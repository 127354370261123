import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "published": false
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Tag = makeShortcode("Tag");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "release-notes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#release-notes",
        "aria-label": "release notes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Release Notes`}</h1>
    <h2 {...{
      "id": "december",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#december",
        "aria-label": "december permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`December`}</h2>
    <ul>
      <li parentName="ul">
        <Tag title="Skyflow Connections UI" tagsList={['improved']} mdxType="Tag" />
        <p parentName="li">{`You can now build your own connection from Skyflow Studio using a wizard UI. Just click the Settings tab for your vault, then click `}<strong parentName="p">{`Create New Connection`}</strong>{`. `}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "november",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#november",
        "aria-label": "november permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`November`}</h2>
    <ul>
      <li parentName="ul">
        <Tag title="Generate Postman Collections" tagsList={['new']} mdxType="Tag" />
        <p parentName="li">{`Just created a new vault and can’t wait to give it a try? You can now generate a Postman Collection for your vault from the Vault Browser, as shown in the GIF below! Import this into Postman, and you’re off to the races.`}</p>
        <p parentName="li"><img parentName="p" {...{
            "src": "/352340a7d4694120bdf6fa21cfb2ca15/postman_select.gif",
            "alt": "postman"
          }}></img></p>
      </li>
      <li parentName="ul">
 <Tag title="Skyflow Connections" tagsList={['new']} mdxType="Tag" />
        <p parentName="li">{` Connections are a Skyflow-managed service that allows you to quickly and securely connect your data vault with third-party services for data processing. By using Skyflow connections, you offload compliance requirements to Skyflow.  `}</p>
      </li>
      <li parentName="ul">
 <Tag title="Server-side SDKs" tagsList={['improved']} mdxType="Tag" />
        <p parentName="li">{` The Java, Python, and Node server-side SDKs now support CRUD operations on data in a vault, making it easier for developers to integrate with Skyflow vaults. Developed with a Golang SDK that includes authentication and vault CRUD operations.`}</p>
      </li>
      <li parentName="ul">
 <Tag title="Client-side SDKs" tagsList={['improved']} mdxType="Tag" />
        <p parentName="li">{` Added support for custom UI elements, unmounting elements in the UI to be able to reset them to their default state, formatting for expiration date and card_number fields, and added support for custom validation rules. `}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "october",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#october",
        "aria-label": "october permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`October`}</h2>
    <ul>
      <li parentName="ul">
        <Tag title="Getting Started Guide" tagsList={['improved']} mdxType="Tag" />
        <p parentName="li">{`New to Skyflow? Check out our brand new `}<a parentName="p" {...{
            "href": "/core-apis/"
          }}>{`Getting Started Guide`}</a>{` to get up and running on Skyflow in just a few minutes. `}</p>
      </li>
      <li parentName="ul">
 <Tag title="Multi-factor Authentication (MFA)" tagsList={['new']} mdxType="Tag" /> 
        <p parentName="li">{` All users that log in to Skyflow Studio in production environments are now required to go through a Two-factor Authentication flow. This is facilitated by entering a time-based one-time password (TOTP) that shows up on your connected Google Authenticator app. `}</p>
      </li>
      <li parentName="ul">
 <Tag title="Server-side Authentication SDKs" tagsList={['new']} mdxType="Tag" />  
        <p parentName="li">{` New Java, Python, and Node server-side SDKs have been released that allow developers to easily generate a bearer token and authenticate with Skyflow.`}</p>
      </li>
      <li parentName="ul">
 <Tag title="Client-side SDKs" tagsList={['improved']} mdxType="Tag" /> 
        <p parentName="li">{` New support for non-PCI elements, debug mode, and updated tokenization methods. The SDK can now detect the type of card being input into the card_numebr field by the format of the card number (e.g., starts with 41 = Visa).`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      